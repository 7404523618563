<template>
  <div>
    <div class="chat-page notranslate">
      <!-- <div v-show="!isShowModalChat || !isDisplayModalChat"> -->
        <ChatModal
          @setShowModal="setShowModalChat"
          :isShowModalChat="isShowModalChat"
          @setDisplayModal="setDisplayModalChat"
          :isDisplayModalChat="isDisplayModalChat"
        />
      <!-- </div> -->
      <!-- <div
        class="chat-page-content"
        v-if="isShowModalChat"
        v-show="isDisplayModalChat"
      >
        <ChatLayout
          @setShowModal="setShowModalChat"
          :isShowModalChat="isShowModalChat"
          @setDisplayModal="setDisplayModalChat"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import axios from "axios";

// import {
//   faArrowCircleRight,
//   faChevronCircleRight,
//   faChevronRight,
//   faClock,
//   faComments,
//   faExclamationCircle,
//   faFileDownload,
//   faFileMedical,
//   faGreaterThanEqual,
//   faMapMarkerAlt,
//   faPaperclip,
//   faSearch,
//   faTimes,
//   faWindowMinimize,
// } from "@fortawesome/free-solid-svg-icons";

// library.add(
//   faTimes,
//   faComments,
//   faChevronCircleRight,
//   faPaperclip,
//   faExclamationCircle,
//   faFileDownload,
//   faArrowCircleRight,
//   faMapMarkerAlt,
//   faClock,
//   faGreaterThanEqual,
//   faFileMedical,
//   faChevronRight,
//   faSearch,
//   faWindowMinimize,
// );

// Vue.component("font-awesome-icon", FontAwesomeIcon);

// import Vuex from "vuex";
// import Vuelidate from "vuelidate";
// import VueCookies from "vue-cookies";
// import VueMoment from "vue-moment";
// import VueGeolocation from "vue-browser-geolocation";
// import * as VueGoogleMaps from "vue2-google-maps";
// import config from "../components/chat/config";

// const moment = require("moment-timezone");
// moment.tz.setDefault("Asia/Bangkok");

// Vue.use(Vuex);
// Vue.use(Vuelidate);
// Vue.use(VueCookies);
// Vue.use(VueMoment, { moment });
// Vue.use(VueGeolocation);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: config.apiKeyGoogleMap,
//     libraries: "places",
//   },
// });
// Vue.prototype.$axios = axios;
// Vue.prototype.$baseUrl = config.chatBaseUrl;
// Vue.prototype.$chatBaseUrlSocket = config.chatBaseUrlSocket;
// Vue.$cookies.config("30d");

import ChatModal from "./ChatModal.vue";
// import ChatLayout from "./chat/ChatLayout.vue";

export default {
  name: "PanelChat",
  components: {
    ChatModal,
    // ChatLayout,
  },
  data() {
    return {
      isShowModalChat: false,
      isDisplayModalChat: false,
    };
  },
  methods: {
    setShowModalChat(show) {
      this.isShowModalChat = show;
    },
    setDisplayModalChat(display) {
      this.isDisplayModalChat = display;
    },
  },
  // mounted() {
  //   let cookiesDomain = window.location.hostname;
  //   window.addEventListener("load", function (e) {
  //     let openTabs = this.$cookies.get("d-chat-ct");
  //     if (openTabs) {
  //       openTabs++;
  //       this.$cookies.set(
  //         "d-chat-ct",
  //         openTabs,
  //         "15min",
  //         "/",
  //         cookiesDomain.replace("www.", "")
  //       );
  //     } else {
  //       this.$cookies.set(
  //         "d-chat-ct",
  //         1,
  //         "15min",
  //         "/",
  //         cookiesDomain.replace("www.", "")
  //       );
  //     }
  //   });
  //   window.addEventListener("unload", function (e) {
  //     e.preventDefault();
  //     let openTabs = this.$cookies.get("d-chat-ct");
  //     if (openTabs) {
  //       openTabs--;
  //       this.$cookies.set(
  //         "d-chat-ct",
  //         openTabs,
  //         "15min",
  //         "/",
  //         cookiesDomain.replace("www.", "")
  //       );
  //     }
  //     e.returnValue = "";
  //   });
  // },
};

(function () {})();
</script>

<style lang="scss" scoped>
.chat-page {
  height: 100%;
  width: 100%;
  z-index: 1;
}
.chat-page-content {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  max-height: 80%;
  height: auto;
  width: 400px;
  z-index: 1000;
}
@media (max-width: 767.98px) {
  .chat-page-content {
    height: 100%;
    width: 100%;
    z-index: 100;
    min-height: 100%;
    bottom: 0rem;
    right: 0rem;
    z-index: 10000;
  }
}
</style>
