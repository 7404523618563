<template>
  <div class="panel-chat-footer">
    <button
      id="btnModalChat"
      class="btn-chat"
      @click="setShowModalChat()"
      v-bind:style="[{ backgroundColor: primaryColor }]"
    >
      <!-- <font-awesome-icon icon="comments" class="icon-chat" /> -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#ffffff" d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1 .9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9 .7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"/></svg>
      <span class="text-chat">{{ title }}</span>
    </button>
  </div>
</template>

<script>
import config from "./chat/config.js";

export default {
  name: "ChatModal",
  props: {
    isShowModalChat: {
      required: true,
      type: Boolean,
    },
    isDisplayModalChat: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      title: config.titleButton,
      primaryColor: config.color,
      showChat: this.isShowModalChat,
      displayChat: this.isDisplayModalChat,
    };
  },
  methods: {
    setShowModalChat() {
      window.open(config.sfRedirectUrl + "", "_blank");
      
      // if (this.isDisplayModalChat) {
      //   this.setDisplayModalChat();
      // } else {
      //   this.setDisplayModalChat();
      //   this.showChat = true;
      //   this.$emit("setShowModal", true);
      // }
    },
    setDisplayModalChat() {
      this.displayChat = true;
      this.$emit("setDisplayModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-chat-footer {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
}
.btn-chat {
  height: 40px;
  width: 100px;
  padding: 0.4rem;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.btn-chat:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.icon-chat {
  font-size: 25px;
  color: white;
}
.text-chat {
  font-size: 20px;
  margin-left: 5px;
  color: white;
  font-family: "DBHeavent";
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}

@media (max-width: 767.98px) {
  .panel-chat-footer {
    bottom: 48px;
  }
}
@media (max-width: 375px) {
  .panel-chat-footer {
    bottom: 74px;
  }
}
</style>
